import Button, { ButtonSize } from 'components/atoms/Tailwind/Button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ReactComponent as OnBoardingEnd } from 'assets/images/onboarding-end.svg';
import { useNavigate } from 'react-router-dom';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { useContext } from 'react';
import { OnBoardingContext } from './Onboarding';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const onboardingEvents: ButtonLayerEvent = {
  item: 'OnBoarding Finished',
  details: 'On Boarding Finished',
  origin: 'OnBoarding',
};

function EndScreen() {
  const navigate = useNavigate();
  const { submitAnswers, loading } = useContext(OnBoardingContext);
  const { t } = useTranslation();

  async function onFinsih() {
    await submitAnswers();
    pushToButtonDataLayer(onboardingEvents);
    navigate('/account#manage_team');
  }

  return (
    <div className='flex flex-col items-center h-screen sm:justify-center'>
      <div>
        <OnBoardingEnd className='w-96 -mb-16 sm:mb-0 sm:w-full' />
      </div>
      <div className='w-fit text-center'>
        <span className='font-bold text-2xl sm:text-3xl text-black-midnight-500'>
          {parse(t('onboarding.end_screen.title'))}
        </span>
      </div>
      <div className='w-fit sm:w-[544px] text-center pt-2 px-4 sm:px-0 sm:pl-[12px]'>
        <span className='text-base text-wrap'>
          {t('onboarding.end_screen.description')}
        </span>
      </div>
      <div className='mt-6'>
        <Button
          size={ButtonSize.MEDIUM}
          text={
            <>
              <span className='text-base leading-5'>{t('onboarding.end_screen.cta')}</span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </>
          }
          onClick={onFinsih}
          isLoading={loading}
        />
      </div>
    </div>
  );
}

export default EndScreen;
