import { UserRole } from 'contexts/UserContext';
import { useTranslation } from 'react-i18next';

export default function UserRolesWarning({
  selectedRole,
}: {
  selectedRole: UserRole
}) {
  const { t } = useTranslation();

  return (
    selectedRole === UserRole.Admin && (
      <div className='text-sm md:text-base text-red-500 text-center mt-2'>{t('user_roles.warning')}</div>
    )
  );
}
