import { tv } from 'tailwind-variants';

export const containerDiv = tv({
  base: 'border-[1px] rounded-md p-4 !shadow-none flex flex-row md:flex-col items-center md:items-start flex-1 gap-4 cursor-pointer',
  variants: {
    isFullWidth: {
      true: 'flex-row md:flex-row items-center md:items-center',
    },
  },
});

export const iconSVG = tv({
  base: 'flex min-w-[32px] w-[32px] h-[32px] justify-center items-center rounded-md',
  variants: {
    isFullWidth: {
      true: 'min-w-[48px] min-h-[48px]',
    },
  },
});
