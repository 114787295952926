import axios, { AxiosResponse } from 'axios';
import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { useAppContext } from 'context/AppContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Loader } from 'components/atoms/Tailwind/Loader/Loader';
import { Tabs } from '@urbansportsclub/components/Tabs';
import { TabsItem } from '@urbansportsclub/components/Tabs/Item';
import { Text } from '@urbansportsclub/components/Text';
import { FaqClickedEvent, TabClickedEvent } from './SupportEvents';
import { CompanyContext } from 'contexts/CompanyContext';

interface ZendeskSection {
  id: number;
  name: string;
}

interface ZendeskResponse {
  sections: ZendeskSection[];
}

interface ZendeskArticle {
  title: string;
  url: string;
  id: number;
  html_url: string;
}

interface ZendeskArticles {
  articles: ZendeskArticle[];
}

function Support() {
  const { language } = useAppContext();
  const { company } = useContext(CompanyContext);
  const [sections, setSections] = useState<ZendeskSection[]>([]);
  const [selectedSection, setSelectedSection] = useState<ZendeskSection>();

  const [articles, setArticles] = useState<ZendeskArticle[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingArticles, setLoadingArticles] = useState(false);

  const getLanguage = useCallback(() => {
    return language === 'de' ? 'de' : 'en-us';
  }, [language]);

  const getSections = useCallback(async () => {
    try {
      setLoading(true);
      const incomingSections: AxiosResponse<ZendeskResponse> = await axios.get(
        `https://usccorporate.zendesk.com/api/v2/help_center/${getLanguage()}/sections`,
      );
      setSelectedSection(incomingSections.data.sections[0]);
      setSections(incomingSections.data.sections);
    } catch (error) {}
  }, [getLanguage]);

  const getSectionArticles = useCallback(async () => {
    try {
      if (!selectedSection) return;
      const incomingArticles: AxiosResponse<ZendeskArticles> = await axios.get(
        `https://usccorporate.zendesk.com/api/v2/help_center/${getLanguage()}/sections/${
          selectedSection?.id ?? ''
        }/articles`,
      );
      setArticles(incomingArticles.data.articles);
      setLoading(false);
      setLoadingArticles(false);
    } catch (error) {}
  }, [getLanguage, selectedSection]);

  useEffect(() => {
    getSections();
  }, [getSections, language]);

  useEffect(() => {
    getSectionArticles();
  }, [getSectionArticles]);

  function onChangeTab(value: string) {
    setLoadingArticles(true);
    const selected = sections.find((section) => section.name === value);
    setSelectedSection(selected);
    TabClickedEvent(selected?.name ?? '');
  }

  return (
    <SidebarLayout heading="Support" loading={loading} showDownloadList={false} showContactUs={company?.csmType?.toLocaleLowerCase() === 'c'}>
      <div className="flex flex-col pt-2 w-full">
        <div className="w-full border-r-0 border-b border-grey-natural-500">
          <Tabs
            defaultOption={selectedSection?.name}
            variant="primary"
            mode="light"
            onChange={onChangeTab}
          >
            {sections.map((section) => (
              <TabsItem key={section.name} value={section.name}>
                {section.name}
              </TabsItem>
            ))}
          </Tabs>
        </div>
        <div>
          <ul className="divide-y divide-grey-natural-500">
            {loadingArticles ? (
              <li className="flex py-6">
                <Loader colors="blue" />
              </li>
            ) : (
              articles.map((article) => (
                <li
                  key={article.id}
                  className="py-6 px-4 flex justify-between items-start sm:items-center space-x-6 [&:hover]:cursor-pointer"
                   onClick={() => {
                     if (navigator.userAgent.includes('Mobile')) {
                       FaqClickedEvent(article.title);
                       window.open(article.html_url, '_blank')?.focus();
                     }
                   }}
                >
                  <Text
                    as="span"
                    weight="medium"
                    intent="sm"
                    className="text-base font-semibold w-4/5"
                  >
                    {article.title}
                  </Text>
                  <span
                  onClick={() => {
                    FaqClickedEvent(article.title);
                    window.open(article.html_url ?? undefined, '_blank')?.focus();
                  }}
                  >
                    <ArrowTopRightOnSquareIcon className="w-5 h-5 text-[#111827] font-bold" />
                  </span>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </SidebarLayout>
  );
}

export default Support;
