import { tv } from 'tailwind-variants';

export const cardsContainerDiv = tv({
  base: 'flex flex-col gap-y-2 gap-x-2 justify-center items-center md:flex-row md:gap-y-0 relative mx-auto',
  variants: {
    isFullWidth: {
      true: 'md:max-w-[calc(100%-75px)]',
    },
  },
});
