import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron_right.svg';

import './Choice.scss';
import { Company } from 'models/company';
import { Badge } from '@urbansportsclub/components/Badge';

export interface ChoiceItem {
  uuid: string,
  id: string,
  text: string,
  data?: any,
  integrationName: string,
  isOnboardingQuestionsEnabled: boolean,
  hrIntegrationName?: string,
  hrIntegrationLastSyncedAt?: string
  isReportsEnabled?: boolean;
  company: Company;
  showNewBadge?: boolean;
}

interface Props {
  item: ChoiceItem,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
}

const Choice = ({ item, onClick }: Props): JSX.Element => (
  <button className="choice heading-5 flex items-center justify-between" onClick={onClick} type="button">
    <div className='flex space-x-3'>
      <span>{item.text}</span>
      {item.showNewBadge && <Badge mode="dark" variant='secondary' className='text-blue-respect-500'>USC</Badge>}
    </div>
    <ChevronRightIcon className="choice__icon" />
  </button>
);

export default Choice;
