import { Card } from '@urbansportsclub/components/Card';
import { IRoleCard } from './RoleCard.types';
import * as styles from './RoleCard.variants';
import { ReactComponent as UserRoleImg } from 'assets/images/user-role.svg';

export default function RoleCard({
  title,
  iconBg,
  description,
  Icon,
  onClick,
  isSelected = false,
  isFullWidth = false,
  isUserSittingOnCard = false,
} : IRoleCard) {
  return (
    <div onClick={onClick} className='flex-1 flex justify-center md:max-w-[265px] w-[100%] relative'>
      {isUserSittingOnCard && isFullWidth && <UserRoleImg className='hidden md:block absolute bottom-[40px] left-[-67px] w-[120px]' />}
      <Card className={styles.containerDiv({ isFullWidth })} variant={`${isSelected ? 'highlighted' : 'presentation'}`}>
        <div className={`${styles.iconSVG({ isFullWidth })} ${iconBg}`} >{Icon}</div>
        <div className='flex flex-col'>
          <span className='text-sm font-semibold'>{title}</span>
          <span className='text-sm'>{description}</span>
        </div>
      </Card>
    </div>
  );
}
