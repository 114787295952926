export const getFromLocalStorage = <T>(key: string): T | string | JSON | undefined => {
  try {
    const serializedState: string | null = localStorage.getItem(key);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState) as JSON | string;
  } catch (err) {
    return undefined;
  }
};

export const saveToLocalStorage = (key: string, value: any): boolean => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
    return true;
  } catch (err) {
    return false;
  }
};

export const saveToLocalStorageWithExpiry = (key: string, value: any, days: number) => {
  const now = new Date();
  const expiryTime = now.getTime() + days * 24 * 60 * 60 * 1000;

  const item = {
    value: value,
    expiry: expiryTime,
  };

  try {
    localStorage.setItem(key, JSON.stringify(item));
    return true;
  } catch (err) {
    return false;
  }
};


export const clearFromLocalStorage = (key: string): boolean => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
};

export const clearLocalStorage = (): boolean => {
  try {
    localStorage.clear();
    return true;
  } catch (err) {
    return false;
  }
};
